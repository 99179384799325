import { Button, Dropdown, QRCode, Row, Table, Input } from "antd";
import Dashboard from "../../../components/layouts/DashLayout";
import * as S from "./styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import UsersAPI from "./UsersAPI";

const MySwal = withReactContent(Swal);

export const Users = () => {
  const [cpf, setCpf] = useState('');
  const [users, setUsers] = useState([]);
  const { getUsers, deleteUser, getUsersByCpf } = UsersAPI();
  const navigate = useNavigate();
  useEffect(() => {
    const loadCompany = async () => {
      const response = await getUsers();

      setUsers(response);
    };

    loadCompany();
  }, []);

  const columns = [
    {
      title: "Nome",
      render: (row) => `${row.name}`,
    },

    {
      title: "Email",
      render: (row) => `${row.email}`,
    },
    {
      title: "Data de criação",
      render: (row) => `${new Date(row.createdAt).toLocaleDateString("pt-BR")}`,
    },

    {
      title: "Ações",
      render: (row, index, column, i) => {
        let items = [
          {
            key: "actions_1_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  navigate("/admin/users/" + row._id);
                }}
              >
                Editar
              </Button>
            ),
          },
          {
            key: "actions_2_" + index,
            label: (
              <Button
                color="danger"
                onClick={async () => {
                  Swal.fire({
                    title: "Deseja mesmo deletar esse usuário?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Deletar",
                    denyButtonText: `Não Deletar`,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      deleteUser(row._id);
                      users.splice(index, 1);
                      setUsers([...users]);
                      Swal.fire("Usuário deletado com sucesso!", "", "success");
                    } else if (result.isDenied) {
                    }
                  });
                }}
              >
                Deletar
              </Button>
            ),
          },
        ];

        return (
          <Dropdown
            menu={{
              items: [...items],
            }}
          >
            <Button href="#" onClick={(e) => e.preventDefault()}>
              <EllipsisOutlined style={{ fontSiz: "25px" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const findByCpf = async () => {
    if (!cpf) {
      await loadCompany();
    }
    const response = await getUsersByCpf(cpf);
    setUsers(response);
  };

  return (
    <Dashboard selected="12">
      <S.Wrapper>
        <Row>
          <Button
            onClick={() => {
              navigate("/admin/users/create");
            }}
          >
            Criar novo usuário
          </Button>
        </Row>
        <div className="flex flex-row gap-2 w-72">
          <Input
            type="text"
            placeholder="Busca por telefone"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
          />
          <Button
            type="primary"
            className="bg-orange-600 text-white"
            onClick={findByCpf}
          >
            Buscar
          </Button>
        </div>
        <Table style={{ width: "80vw" }} columns={columns} dataSource={users} />
      </S.Wrapper>
    </Dashboard>
  );
};
